.deposit-address-container {
    display: block; 
    width: 100%; 
    max-width: 100%; 
    overflow: hidden; 
  }
  
  .deposit-address {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px; 
  }
  
/* Ensure proper width control */
@media (min-width: 992px) { /* Laptop screens */
    .deposit-address {
        max-width: 180px;
    }
}

@media (max-width: 576px) { /* Target mobile screens */
    .deposit-address {
      max-width: 200px; 
    }
  }
  
/* Default gap for larger screens */
.custom-gap {
    gap: 50px;
}

/* Mobile screens (width ≤ 768px) */
@media (max-width: 768px) {
    .custom-gap {
        gap: 22px;
    }
}

/* Icon styling */
.icon-style {
    font-size: 20px;
    color: var(--icon-color);
}

/* Count styling */
.count-style {
    font-size: 13px;
    color: var(--icon-color);
}
