.contact-social-img img{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

#contact-wrapper{
    margin-bottom: 10%;
}

.get-in-touch-left{
    width: 50%;
}

@media screen and (max-width: 768px) {
    #contact-wrapper{
      flex-direction: column;
      width: 100%;
      gap: 50px;
    }

    .get-in-touch-left{
        width: 100%;
    }
  }

  .location-icon {
    width: 20px; /* Adjust size for mobile */
    height: auto;
  }
  
  .location-text {
    font-size: 16px; /* Ensures readability on mobile */
    word-wrap: break-word; /* Allows text to wrap properly */
    overflow-wrap: break-word;
  }
  
  .contact-input-wrapper {
    max-width: 100%; /* Prevents overflow */
    word-break: break-word; /* Forces breaking on long words */
    overflow: hidden;
  }
  
  @media (max-width: 576px) { /* Mobile adjustments */
    .location-text {
      font-size: 14px; /* Slightly reduce font size */
    }
  
    .location-icon {
      width: 16px; /* Reduce icon size */
    }
  
    .contact-input-wrapper {
      padding-bottom: 2px; /* Adjust spacing */
    }
  }
  