@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --primary-text-color: #183b56;
    --secondary-text-color: #7E7E80;
    --accent-color: #2294ed;
    --accent-color-dark: #1d69a3;
}

body {
    font-family: "Poppins", sans-serif;
    color: var(--primary-text-color);
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

.flex {
    display: flex;
    align-items: center;
}

.container {
    max-width: 1180px;
    margin-inline: auto;
}

/* Increase max-width for extra-large screens (>= 1400px) */
@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}

/* Further increase max-width for ultra-wide screens (>= 1600px) */
@media (min-width: 1600px) {
    .container {
        max-width: 1800px;
    }
}

/* Ensure responsiveness for smaller screens */
@media (max-width: 1200px) {
    .container {
        max-width: 95%;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
        padding: 0 15px;
    }
}

nav {
    /* background-color: #f3faff; */
    /* justify-content: space-between; */
}

.main-nav {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    justify-content: space-between;
}

.company-logo img {
    width: 131px;
    height: 54px;
    left: 135px;
    top: 13px;
    cursor: pointer;
}

.hover-link {
    cursor: pointer;
    /* padding-inline: 10px; */
    justify-content: space-between;
    transition: 0.3s ease-out;
    padding-inline: 8px;
    color: #000000;
}

.hover-link:hover {
    /* border-bottom: 2px solid #069514; */
    color: #069514;
    transition: 0.2s ease-in;
}


.signup-button {
    padding: 8px 16px;
    /* width: 95px;
    height: 46px; */
    background: #069514d2;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    margin-inline: 12px;
    transition: 0.3s ease-out;
}

.signup-button:hover {
    background-color: #0695148b;
}

/* Banner Section */

.banner-container {
    background-color: #d7d7d7;
    width: 100vw;
    height: 80vh;
    /* padding-left: 80px; */
    /* padding-top: 80px; */
    position: static;
}

.floating-container {
    /* position: relative; */
    /* justify-content: center; */
    width: 100vw;
    height: 100vh;
    background-image: url('/public/images/banner.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

/* .banner-container {
    width: 100%;
} */

.floating-text-container {
    justify-content: start;
    flex-direction: column;
    position: relative;
    /* left: 60px; */
}


.floating-text-container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: left;
}

.floating-text {
    position: absolute;
    top: 40%;
    left: 10%;
    /* transform: translate(-50%, -50%); */
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
    font-size: 30px;
    min-height: 100px;
}

.floating-text span {
    color: #069514;
}

.floating-text h1 {
    font-size: 40px;
}

.floating-text p {
    font-size: 22px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    text-transform: uppercase;
}

.green-line-container {
    padding-right: 20px;
}

.floating-image {
    position: absolute;
    top: 50%;
    right: -16%;
    transform: translate(-50%, -50%);
    width: 588px;
    /* adjust the width as needed */
    height: 307px;
    /* adjust the height as needed */
    z-index: 3;
}

.trade-text-container {
    position: absolute;
    top: 85%;
    left: 27%;
    transform: translate(-50%, -50%);
    color: #fff;
    height: 307px;
    width: 460px;
    font-size: 22px;
    z-index: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
}

.explore-container {
    position: absolute;
    top: 100%;
    left: 17%;
    transform: translate(-50%, -50%);
    /* width: 588px; */
    /* adjust the width as needed */
    height: 307px;
    /* adjust the height as needed */
    z-index: 3;
}

.explore-button {
    width: 157px;
    height: 50px;
    background: #069514;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    margin-inline: 8px;
    transition: 0.3s ease-out;
    font-family: 'Poppins';
    font-size: 16px;
    border: none;
}

.explore-button:hover {
    background-color: #0695148b;
}

.confetti {
    z-index: 2;
}

.base {
    /* adjust the height as needed */
    z-index: 1;
}


/* How it Section */
.howit {
    padding-top: 72px;
    flex-direction: column;
    flex-wrap: wrap;
}

.howit-text {
    justify-content: center;
    flex-direction: column;
}

.round-container {
    justify-content: space-around;
    gap: 160px;
}

.round-image {
    width: 30%;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
    margin-top: 39px;
}

.round-image h3 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    width: 350px;
}

.round-image img {
    width: 100px;
    height: 100px;
}

/* Betting Section */
.betting-section {
    padding: 40px 0;
    background-color: #f8f9fa;
}

.prediction-card {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin-bottom: 32px;
    transition: transform 0.2s ease;
    max-width: 100%; /* Ensures card doesn't exceed container width */
}

.prediction-card:hover {
    transform: translateY(-2px);
}

.prediction-image-wrapper {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.prediction-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.prediction-card:hover .prediction-image {
    transform: scale(1.02);
}

.prediction-question {
    font-size: 1.1rem;
    font-weight: 500;
    color: #2d3436;
    margin: 0;
    line-height: 1.5;
}

.prediction-actions {
    background: #fff;
    border-top: 1px solid #eee;
}

.probability-badges .badge {
    font-size: 0.9rem;
    padding: 8px 16px;
    font-weight: 500;
}

.action-buttons .btn {
    padding: 8px 24px;
    font-weight: 500;
    min-width: 120px;
}

/* Responsive styles */
@media (max-width: 991.98px) {
    .prediction-card {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 767.98px) {
    .betting-section {
        padding: 20px 0;
    }
    
    .prediction-card {
        margin-left: 10px;
        margin-right: 10px;
    }

    .prediction-question {
        font-size: 1rem;
    }

    .prediction-actions {
        padding: 12px !important;
    }

    .probability-badges .badge {
        font-size: 0.8rem;
        padding: 6px 12px;
    }

    .action-buttons .btn {
        padding: 6px 16px;
        min-width: 100px;
        font-size: 0.9rem;
    }
}

@media (max-width: 575.98px) {
    .prediction-card {
        margin-left: 5px;
        margin-right: 5px;
    }

    .prediction-actions .d-flex {
        flex-direction: column;
        gap: 16px;
    }

    .probability-badges {
        width: 100%;
        justify-content: center;
    }

    .action-buttons {
        width: 100%;
    }

    .action-buttons .btn {
        flex: 1;
        min-width: unset;
    }
}

/* Footer Section */
footer {
    background-color: #000000;
    height: 102px;
}

.footer-section {
    justify-content: space-between;
    width: 100%;
}

.footer-nav {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    justify-content: space-around;
}

.footer-nav li {
    margin-inline: 29px;
    transition: 0.3s ease-out;
}

.footer-nav li:hover {
    color: #069514;
}

.footer-logo {
    color: #069514;
    font-weight: 600;
    font-size: 40px;
}

.social-media-logo img {
    /* width: 30px;
    height: 30px; */
    margin-left: 12px;
    cursor: pointer;
}

.betting-questions {
    width: 100vw;
    padding-inline: 10px;
}

.navbar-collapse {
    display: flex;
    align-items: center;
    justify-content: end;
}



.bg-footer {
    background-color: #33383c;
    padding: 50px 0 30px;
}

.footer-heading {
    letter-spacing: 2px;
}

.footer-link a {
    color: #acacac;
    line-height: 40px;
    font-size: 14px;
    transition: all 0.5s;
}

.footer-link a:hover {
    color: #1bbc9b;
}

.contact-info {
    color: #acacac;
    font-size: 14px;
}

.footer-social-icon {
    font-size: 15px;
    height: 34px;
    width: 34px;
    line-height: 34px;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
}

.facebook {
    background-color: #4e71a8;
    color: #ffffff;
}

.twitter {
    background-color: #55acee;
    color: #ffffff;
}

.google {
    background-color: #d6492f;
    color: #ffffff;
}

.apple {
    background-color: #424041;
    color: #ffffff;
}

.footer-alt {
    color: #acacac;
}

.footer-heading {
    position: relative;
    padding-bottom: 12px;
}

.footer-heading:after {
    content: '';
    width: 25px;
    border-bottom: 1px solid #FFF;
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    border-bottom: 1px solid #1bbc9b;
}

/* .banner{
    background-image: url("/public/images/Rectangle17.svg");
    height: 100vh;
   width: 100%;
    background-repeat: no-repeat;
} */

/* Media query */
@media screen and (max-width: 850px) {
    .round-image h3 {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        width: 200px;
    }

    .banner-container {
        background-color: #f5eeee;
        width: 100vw;
        height: 100vh;
        /* padding-left: 40px; */
        padding-top: 10px;
        position: static;
    }

    .banner-text-upper {
        font-size: 20px;
        border-left: 10px solid #069514;
    }

    .nav-menu {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

@media screen and (max-width: 992px) {
    .feed-float-home {
        flex-direction: column;
    }

    .feed-float-border-home {
        width: 100%;
        padding: 0 5px;
    }
}

@media (max-width: 768px) {
    .feed-image-section-home {
        border-radius: 25px;
        height: 300px;
    }

    .feed-profile-image-home {
        margin-right: 8px;
    }

    .trending-wrapper {
        font-size: 14px;
    }

    .question {
        font-size: 14px;
    }

    .username {
        font-size: 14px;
    }
}

/* Add these styles to your existing CSS */

.banner {
    padding: 60px 0;
    background-color: #fff;
}

.banner h1 {
    font-size: 3.5rem;
    line-height: 1.2;
}

.banner .text-success {
    color: #069514 !important;
}

.btn-success {
    background-color: #069514;
    border-color: #069514;
}

.btn-success:hover {
    background-color: #058012;
    border-color: #058012;
}

.banner .bg-light {
    background-color: #f8f9fa;
    border-radius: 12px;
}

.banner img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .banner h1 {
        font-size: 2.5rem;
    }
    
    .banner {
        padding: 40px 0;
    }
}

/* Prediction Cards Styling */
.prediction-card {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    margin-bottom: 24px;
}

.prediction-card .avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.prediction-card .card-header {
    background: #fff;
    border-bottom: 1px solid #eee;
}

.prediction-question {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    margin: 0;
}

.prediction-image {
    height: 400px;
    object-fit: cover;
}

.prediction-actions {
    background: #fff;
    border-top: 1px solid #eee;
}

.probability-badges .badge {
    font-size: 0.9rem;
    padding: 8px 16px;
}

.action-buttons .btn {
    padding: 8px 24px;
    font-weight: 500;
}

.engagement-stats {
    color: #666;
    font-size: 0.9rem;
}

.engagement-stats span {
    display: flex;
    align-items: center;
    gap: 4px;
}

.engagement-stats i {
    font-size: 1.1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .prediction-card {
        border-radius: 12px;
    }

    .prediction-image {
        height: 300px;
    }

    .prediction-actions {
        padding: 12px !important;
    }

    .action-buttons .btn {
        padding: 6px 16px;
        font-size: 0.9rem;
    }

    .probability-badges .badge {
        font-size: 0.8rem;
        padding: 6px 12px;
    }

    .engagement-stats {
        font-size: 0.8rem;
    }
}

@media (max-width: 576px) {
    .prediction-actions {
        flex-direction: column;
        gap: 16px;
    }

    .action-buttons {
        width: 100%;
        display: flex;
        gap: 8px;
    }

    .action-buttons .btn {
        flex: 1;
    }

    .prediction-image {
        height: 250px;
    }
}

