.market-bet-btn {
    width: 47px;
    height: 29px;
    padding: 4px 4px;
    border-radius: 15px;
    border: 1px solid #069514;
    color: #069514;
    transition: 0.2s ease-in-out;
}

.market-bet-btn:hover{
    background-color: #a0dfa8d8;
}

.market-profile-img{
    width: 36px;
    height: 36px;
}

.card {
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-2px);
}

.card.dark {
  background-color: #1a1a1a;
  color: white;
  border: 1px solid #333;
}

.card.dark .text-muted {
  color: #aaa !important;
}

.search-container input {
  height: 40px;
  border-radius: 6px;
}

.form-control {
  border-radius: 20px;
  padding: 10px 20px;
}

.form-select {
  border-radius: 20px;
  padding: 10px 20px;
  width: auto;
}