/* Sidebar */
.sidebar {
  width: 271px;
  position: fixed;
  height: 100vh;
  background: white;
  /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

/* Mobile View (Hide by default) */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }
  .sidebar.open {
    transform: translateX(0);
  }
}

/* Close Button (Only in Mobile) */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 22px;
  color: #333;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .close-btn {
    display: block;
  }
}

/* Logo */
.logo {
  text-align: center;
  margin-bottom: 20px;
}

.logo-img {
  width: 179px;
  height: 29px;
  display: block;
  margin: 0 auto;
}

/* Navigation Links */
.nav {
  margin-top: 30px;
  list-style: none;
  padding: 0;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  font-size: 16px;
  color: #333;
  border-radius: 8px;
  transition: 0.3s;
  text-decoration: none;
}

.nav-icon {
  font-size: 20px;
  margin-right: 10px;
}

/* Active Link */
.nav-link.active {
  background: #e8ffe8;
  color: #28a745;
  font-weight: bold;
}

/* Logout Button */
.logout {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.btn-logout {
  background: none;
  border: none;
  color: #28a745;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the start */
  font-weight: bold;
  border-radius: 8px;
  transition: 0.3s;
}

/* Sidebar Toggle (Mobile Only) */
.sidebar-toggle {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 1001;
  font-size: 22px;
  background: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .sidebar-toggle {
    display: block;
  }
}

/* Background Overlay (Mobile Only) */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

@media (min-width: 769px) {
  .overlay {
    display: none;
  }
}


/* Add these new styles */
.submenu {
  padding-left: 0 !important;
  margin-top: -5px;
}

.submenu-link {
  padding: 10px 15px 10px 48px !important;
  font-size: 14px;
}

.submenu.active {
  background-color: rgba(255, 255, 255, 0.1);
}

/* For dark mode */
/* .dark-mode .submenu .nav-link.active {
  background-color: rgba(0, 0, 0, 0.2);
} */

/* Make the button look like a regular nav link */
.submenu button.nav-link {
  width: 100%;
  border: none;
  padding: 10px 15px 10px 48px !important;
}



.dark-mode .submenu button.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

